import './App.css'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ClientView from './pages/clientview/index';
function App() {
  return (
    <Router>
      <Routes>
          <Route path='/' Component={ClientView}></Route>
      </Routes>
    </Router>
  );
}

export default App;
