import React, { Component } from 'react';
import LazyLoad from 'react-lazy-load';

import Styled from 'styled-components';
import {
    FaFacebookF,
    FaLinkedinIn,
    FaYoutube,
    FaRegEnvelopeOpen,
    FaCode,
    FaGithub,
    FaFolderOpen,
    FaEnvelopeOpen,
    FaLink,
    FaEye,
    FaWindowClose,
    FaRegFilePdf
} from "react-icons/fa";
import { Link } from 'react-router-dom';

import YubarajRaiImagePath from '../../images/yubaraj-rai.svg';
import sectionBG from '../../images/section-bg-image.jpg';  
import sectionBG2 from '../../images/section-bg2-image.png';
import sectionBG3 from '../../images/section-bg3-image.jpg';

import iconDesign from '../../images/icon-design-image.png';
import iconDevelopment from '../../images/icon-development-image.png';
import iconPHPMySQL from '../../images/icon-phpmysql-image.png';
import iconWordPress from '../../images/icon-wordpress-image.png';
import iconLaravel from '../../images/icon-laravel-image.png';
import iconMERN from '../../images/icon-mern-image.png';
import iconHardware from '../../images/icon-hardware-image.png';
import iconNetworking from '../../images/icon-networking-image.png';
import iconWebHosting from '../../images/icon-webhosting-image.png';

import portfolioOne from '../../portfolio/ccrr-thumbnail.jpg';
import portfolioTwo from '../../portfolio/tenutevirone-thumbnail.jpg';
import portfolioThree from '../../portfolio/miraigroups-thumbnail.jpg';
import portfolioFour from '../../portfolio/nabanepal-thumbnail.jpg';
import portfolioFive from '../../portfolio/daemmfabrik-de-thumbnail.jpg';
import portfolioSix from '../../portfolio/nirajshrestha-thumbnail.jpg';
import portfolioSeven from '../../portfolio/b2c-c2b-thumbnail.jpg';
import portfolioEight from '../../portfolio/trekking-thumbnail.jpg';
import portfolioTen from '../../portfolio/accademiafumettotrieste.jpg';

import Resume from '../../portfolio/Resume_YubarajRai.2023.pdf';

import './home.css';

const SectionWithBG = Styled.section`background: url('${sectionBG}') bottom/cover no-repeat fixed`;
const SectionWelcome = Styled.section`background: url('${sectionBG2}') bottom/cover no-repeat fixed`;
const SectionWithBG3 = Styled.section`background: url('${sectionBG3}') bottom/cover no-repeat fixed`;

class Home extends Component {
    state = {}

    componentDidMount = () => {
        let header = document.getElementById('header'),
            sectionWelcome = document.getElementsByClassName('section-welcome');

        if (sectionWelcome.length !== 0) {
            sectionWelcome[0].style.paddingTop = (header.clientHeight * 1.5) + 'px';
        }

        let closePreview = document.querySelectorAll('.preview__close'),
            preview = document.querySelectorAll('.preview'),
            bodyPopupActive = document.querySelectorAll('body');

        for (let i = 0; i < closePreview.length; i++) {
            closePreview[i].addEventListener('click', (e) => {
                e.preventDefault();
                Object.keys(preview).map(preview_handler => {
                    preview[preview_handler].classList.remove('preview--active');
                    return 1;
                })

                Object.keys(bodyPopupActive).map(bodyPopup__active => {
                    if (bodyPopupActive[bodyPopup__active].classList.contains('popup-active')) {
                        bodyPopupActive[bodyPopup__active].classList.remove('popup-active');
                    }
                    return 1;
                })
            })
        }

        let togglePreview = document.querySelectorAll('.preview__toggle'),
            bodyPopup = document.querySelectorAll('body');

        for (let i = 0; i < togglePreview.length; i++) {
            togglePreview[i].addEventListener('click', (e) => {
                e.preventDefault();
                togglePreview[i].parentElement.parentElement.nextElementSibling.classList.add('preview--active');
                Object.keys(bodyPopup).map(bodyPopup__handler => {
                    bodyPopup[bodyPopup__handler].classList.add('popup-active');
                    return 1;
                })
            });
        }

        /**
         * Scroll Offset
         */
        let sectionWindow = document.getElementsByTagName('section'),
            menuAnchor = document.querySelectorAll('#menu a');
        window.addEventListener('scroll', function () {
            Object.keys(sectionWindow).map(section => {
                if (window.pageYOffset > (sectionWindow[section].offsetTop - 20)) {
                    Object.keys(menuAnchor).map(anchor => {
                        if (`#${sectionWindow[section].id}` === menuAnchor[anchor].hash) {
                            let menuChildren = menuAnchor[anchor].parentElement.parentElement.children;
                            Object.keys(menuChildren).map(children => {
                                menuChildren[children].classList.remove('current-menu-item');
                                return 1;
                            });
                            menuAnchor[anchor].parentElement.classList.add('current-menu-item');
                        }
                        return 1;
                    });
                }
                return 1;
            });

            if (window.pageYOffset > 2) {
                header.classList.add('fixed-top');
            } else {
                header.classList.remove('fixed-top');
            }
        });


    }

    render() {
        return (
            <>
                <SectionWelcome id="welcome" className="section-welcome">
                    <div className="box col-holder">
                        <div className="user-holder col col-md-6 d-flex align-items-flex-end">
                            <figure>
                                <LazyLoad debounce={false} >
                                    <img src={YubarajRaiImagePath} alt="Yubaraj Rai" />
                                </LazyLoad>
                            </figure>
                        </div>
                        <div className="content-box relative-1 col col-md-6 d-flex align-items-flex-end justify-content-flex-end-large">
                            <h1>
                                <span>Hi, I'm Yubaraj Rai</span>
                                <span className="typewriter">
                                    <span>Full-stack web developer</span>
                                </span>
                                <small>I build awesome websites and web applications</small>
                            </h1>
                        </div>
                    </div>
                </SectionWelcome>
                <section id="about" className="section section-about relative section-padding text-center section-shadow-top">
                    <div className="box col-holder">
                        <div className="col col-12">
                            <div className="box-inner">
                                <blockquote>
                                    <small>Developer as a Service since 2013.</small><br/>
                                    <span>I handle <strong>devs</strong> &amp; <strong>security</strong></span>,<br/>You handle <strong>growth</strong> &amp; <strong>presence</strong>.
                                </blockquote>
                                <hr />
                                <p>I began my career as an intern in a web agency, where I first learnt the basics of building web applications. Few months later, I became a CSS Ninja and after a few more months I was promoted to Head CSS Ninja. I did nothing but front-end development for a year, until WordPress and then Laravel, happened in my life. Once I started learning and growing there was no stopping. I began building responsive websites since 2014 and lately I’ve been mastering the tricks of Parallax and CSS3 animations.</p>
                                <div className="social-links">
                                    <Link to="//www.facebook.com/sangam.name/" className="facebook" title="Facebook" target="_blank"><FaFacebookF /></Link>
                                    <Link to="//www.youtube.com/channel/UCoxnLlOKmUPfipEdvRNdTyQ/" className="youtube" title="YouTube" target="_blank"><FaYoutube /></Link>
                                    <Link to="//www.linkedin.com/in/yubaraj-rai-028b1899/" className="linkedin" title="LinkedIn" target="_blank"><FaLinkedinIn /></Link>
                                    <Link to="//github.com/yubarajrai" className="github" title="Github" target="_blank"><FaGithub /></Link>
                                </div>
                                <div className="my-external-profile-box">
                                    <hr />
                                    <p>I am working as a <strong>Technical Project Manager</strong> at Stupa Technology since January 1, 2018.</p>
                                    <hr />
                                    <p className="resume-pdf"><Link to={Resume} target="_blank"><strong>Resume PDF</strong>&nbsp;&nbsp;<FaRegFilePdf /></Link></p>
                                    <p className=""><a href="mailto:connectyubaraj@gmail.com" className="button button-primary" title="E-Mail"><FaRegEnvelopeOpen />&nbsp;&nbsp;&nbsp;Hire Me !</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SectionWithBG id="whatIdo" className="section section-padding relative text-center clip-path-1">
                <h1 className="title-tag">
                    [ <FaCode /> ]
                    <small>Developer as a Service</small>
                </h1>
                <div className="box col-holder">
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>MongoDB / Express.JS / React.JS / Node.JS</span>
                            </span>
                            <figure>
                                <img src={iconMERN} alt="MEARN Stack Development" title="MEARN Stack Development" />
                            </figure>
                            <h2>MERN-stack</h2>
                            <p>In 2018 AD, I started writing JavaScript and self-taught myself to handle minor Scripting Events and Customizations on different project. After a year I realize that MERN-stack will be effective learning, I partially have started to work as a MERN-stack developer and I am super loving it.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>Content Management System</span>
                            </span>
                            <figure>
                                <img src={iconWordPress} alt="WordPress" title="WordPress" />
                            </figure>
                            <h2>WordPress</h2>
                            <p>I love WordPress. In 2015 AD, I joined the ‘community’ and
                                self-taught myself to make minor tweaks and customizations in someone else’s themes. After a few
                                months I was building my own themes and four years later I build websites entirely on WordPress.
                                And, I have contributed my knowledge as a Theme Reviewer in WordPress Community.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>PHP Framework</span>
                            </span>
                            <figure>
                                <img src={iconLaravel} alt="Larevel" title="Larevel" />
                            </figure>
                            <h2>Laravel</h2>
                            <p>I love Laravel - the affair began in 2018. It was the year I joined the ‘community’ and
                                self-taught myself to make minor blog and customizations in framework. After a few months I was
                                building my own structure and later I build websites entirely on Laravel.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>Professional Service</span>
                            </span>
                            <figure>
                                <img src={iconDevelopment} alt="Web Development" title="Web Development" />
                            </figure>
                            <h2>Web Development</h2>
                            <p>I started doing front-end development from 2014. I built up a solid foundation in html,
                                css, jQuery and php, later on upgrading to HTML5 and CSS3. I began building responsive websites since
                                2015 and lately I’ve been making websites using build system such as Gulp, and package manager like Bower.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>Web Hosting Reseller</span>
                            </span>
                            <figure>
                                <img src={iconWebHosting} alt="Web Hosting, Domain Registration" title="Web Hosting, Domain Registration" />
                            </figure>
                            <h2>Web Hosting, Domain Registration</h2>
                            <p>"Flexibility is everything today". Working long time as a Web Developer, I have started <strong>Web Hostign Reseller</strong> with best packages and 24/7 service. <br/>You can find all kind of hosting here. Limited / Unlimited, Shared / Single etc.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>Professional Service</span>
                            </span>
                            <figure>
                                <img src={iconDesign} alt="Web Designing" title="Web Designing" />
                            </figure>
                            <h2>Designing</h2>
                            <p>I started my job as a graphic designer. I love Photoshop, Illustrator, and InDesign. I
                                started graphics designing from 2008. I worked at Hardford Infotech Center as a Graphics Designing
                                Instructor and System Admin. Lately, I joined Commander Post Daily News Paper as a graphics designer.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidthm relative">
                            <span className="profile-cart__tag">
                                <span>Microsoft Certified Professional</span>
                            </span>
                            <figure>
                                <img src={iconHardware} alt="Card Level computer Maintenance" title="Card Level computer Maintenance" />
                            </figure>
                            <h2>Card Level computer Maintenance</h2>
                            <p>After working long time as a Computer Technician for various companies and Government or Non-government Organiztions (eg. Local Bodies Fiscal Commission - LBFC), I have done Microsoft Certification also as a <strong>Microsoft Certified Professional (MCP)</strong> in 2010 AD. and, continously working as a Professional Computer Technician.</p>
                        </div>
                    </div>
                    <div className="col col-sm-6 col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>Client &amp; Server Networking</span>
                            </span>
                            <figure>
                                <img src={iconNetworking} alt="Computer Networking" title="Computer Networking" />
                            </figure>
                            <h2>Computer Networking</h2>
                            <p>I have started my IT Career as an Instructor and Computer Technician in 2010. From that point, I am still working as a Computer Hardware and Networking Professional. My key expertise are <strong>Card Level Computer Hardware</strong>, &amp; <strong>Private Networking</strong> which is local area networks (LANs) in residential, office, and enterprise environments.</p>
                        </div>
                    </div>
                    <div className="col col-lg-4 d-flex">
                        <div className="profile-cart fullwidth relative">
                            <span className="profile-cart__tag">
                                <span>Programming Lanugage &amp; Database</span>
                            </span>
                            <figure>
                                <img src={iconPHPMySQL} alt="PHP / MySQL" title="PHP / MySQL" />
                            </figure>
                            <h2>PHP / MySQL</h2>
                            <p>After doing front-end development long time I realise that programming is more
                                interesting, and I started learning Core PHP and MVC from 2016. While learning PHP language I started to work on
                                MVC and lately I’ve been creating websites using WordPress CMS. In short, I work on WordPress and Core
                                PHP [MVC]. </p>
                        </div>
                    </div>
                </div>
            </SectionWithBG>
            
                <section id="portfolio" className="section relative text-center">
                    <h1 className="title-tag">
                        My Portfolio&nbsp;&nbsp;<FaFolderOpen />
                        <small>Full-stack Web Development &amp; Designs</small>
                    </h1>
                    
                    <div className="box col-holder">
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//miraigroups.com/" title="Mirai Groups | Educational by Yubaraj Rai" target="_blank">
                                        <img src={portfolioThree} alt="Mirai Groups | Educational" title="Mirai Groups | Educational" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//miraigroups.com/" title="Mirai Groups | Educational by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Mirai Groups | Educational" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioThree} alt="Mirai Groups | Educational" title="Mirai Groups | Educational" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//miraigroups.com/" title="Mirai Groups | Educational by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Mirai Groups | Educational</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Custom Framework using Composer with listed Packages:</p>
                                                <span className="tag">PSR0 / PSR4 Rules to handle Custom Post Type Object</span><span className="tag">Custom Widget</span>
                                                <span className="tag">Carbon Fields</span><span className="tag">CMB2</span><span className="tag">Kirki</span>
                                                <br /><br />
                                                <h5>Front-end Framework</h5>
                                                <span className="tag">Foundation</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//miraigroups.com/" title="Mirai Groups | Educational by Yubaraj Rai" target="_blank">
                                        Mirai Groups | Educational
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//mymobizilla.com/" title="B2C & C2B E-Commerce by Yubaraj Rai" target="_blank">
                                        <img src={portfolioSeven} alt="B2C & C2B E-Commerce" title="B2C & C2B E-Commerce" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//mymobizilla.com/" title="B2C & C2B E-Commerce by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of B2C & C2B E-Commerce" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioSeven} alt="B2C & C2B E-Commerce" title="B2C & C2B E-Commerce" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//mymobizilla.com/" title="B2C & C2B E-Commerce by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>B2C & C2B E-Commerce</h3>
                                            <p>This project has been developed under Laravel (PHP Framework) with ReactJS and Laravel API technology on Front-end.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Laravel (PHP Framework):</p>
                                                <span className="tag">Multi Authentication</span>
                                                <span className="tag">Laravel API for Front-end</span>
                                                <span className="tag">Pure JavaSCript on tag system</span><span className="tag">Easy User Experience</span>
                                                <br /><br />
                                                <h5>Front-end Technology</h5>
                                                <span className="tag">ReactJS</span>
                                                <span className="tag">Axios on API handling</span>
                                                <span className="tag">Ajax data filter</span>
                                                <span className="tag">Custom pagination</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//mymobizilla.com/" title="B2C & C2B E-Commerce by Yubaraj Rai" target="_blank">
                                        B2C & C2B E-Commerce
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//www.daemmfabrik.de/" title="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German by Yubaraj Rai" target="_blank">
                                        <img src={portfolioFive} alt="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German" title="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//www.daemmfabrik.de/" title="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioFive} alt="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German" title="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//www.daemmfabrik.de/" title="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>THEME (PRO VERSION):</h4><br />
                                                <h5>Avada Website Builder</h5>
                                                <span class="tag">Pro Avada Theme</span>
                                                <span class="tag">Germany Language</span>
                                                <span class="tag">WooCommerce</span>
                                                <span class="tag">Easy User Experience</span>
                                                <br />
                                            </div>
                                            <hr />
                                            <div class="preview__job">
                                                <h4>Contributions</h4><br />
                                                <ul>
                                                    <li>Designed and Hosting Mgmt. by: Digi Soft Tech, Nepal</li>
                                                    <li>Developed by Yubaraj Rai</li>
                                                </ul>
                                                <hr />
                                                <p><em><strong>Note:</strong> Complete website has been maintained in German Language.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//www.daemmfabrik.de/" title="Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German by Yubaraj Rai" target="_blank">
                                        Dämmfabrik | Dämmen wie die Profis - Dämmfabrik, German
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//tenutevirone.com/" title="Tenute Virone, Italy by Yubaraj Rai" target="_blank">
                                            <img src={portfolioTwo} alt="Tenute Virone, Italy" title="Tenute Virone, Italy" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//tenutevirone.com/" title="Tenute Virone, Italy by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Tenute Virone, Italy" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioTwo} alt="Tenute Virone, Italy" title="Tenute Virone, Italy" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//tenutevirone.com/" title="Tenute Virone, Italy by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Tenute Virone, Italy</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Custom Framework using Composer with listed Packages:</p>
                                                <span className="tag">PSR0 / PSR4 Rules to handle Custom Post Type Object</span><span className="tag">Custom Widget</span>
                                                <span className="tag">Carbon Fields</span><span className="tag">CMB2</span><span className="tag">Kirki</span>
                                                <br /><br />
                                                <h5>Front-end Framework</h5>
                                                <span className="tag">Foundation</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>Domain and Hosting Registration</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                    <li>SSL Configuration</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//tenutevirone.com/" title="Tenute Virone, Italy by Yubaraj Rai" target="_blank">
                                        Tenute Virone, Italy
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//ccrr.online.trieste.it/" title="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy by Yubaraj Rai" target="_blank">
                                        <img src={portfolioOne} alt="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy" title="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//ccrr.online.trieste.it/" title="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioOne} alt="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy" title="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//ccrr.online.trieste.it/" title="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Custom Framework using Composer with listed Packages:</p>
                                                <span className="tag">PSR0 / PSR4 Rules to handle Custom Post Type Object</span><span className="tag">Custom Widget</span>
                                                <span className="tag">Carbon Fields</span><span className="tag">CMB2</span><span className="tag">Kirki</span>
                                                <br /><br />
                                                <h5>Front-end Framework</h5>
                                                <span className="tag">Foundation</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//ccrr.online.trieste.it/" title="Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy by Yubaraj Rai" target="_blank">
                                        Consiglio Comunale delle Ragazze e dei Ragazzi – Comune di Trieste, Italy
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="http://www.accademiafumettotrieste.it/" title="Accademia di Fumetto di Trieste, Italy" target="_blank">
                                        <img src={portfolioTen} alt="Accademia di Fumetto di Trieste, Italy" title="Accademia di Fumetto di Trieste, Italy" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="http://www.accademiafumettotrieste.it/" title="Accademia" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Accademia di Fumetto di Trieste, Italy" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioTen} alt="Accademia di Fumetto di Trieste, Italy" title="Accademia di Fumetto di Trieste, Italy" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="http://www.accademiafumettotrieste.it/" title="Accademia di Fumetto di Trieste, Italy by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Accademia di Fumetto di Trieste, Italy</h3>
                                            <p>This project has been developed under Laravel (PHP Framework) with ReactJS and Laravel API technology on Front-end.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Laravel (PHP Framework):</p>
                                                <span className="tag">Multi Authentication</span>
                                                <span className="tag">Laravel API for Front-end</span>
                                                <span className="tag">Pure JavaSCript on tag system</span><span className="tag">Easy User Experience</span>
                                                <br /><br />
                                                <h5>Front-end Technology</h5>
                                                <span className="tag">ReactJS</span>
                                                <span className="tag">Axios on API handling</span>
                                                <span className="tag">Ajax data filter</span>
                                                <span className="tag">Custom pagination</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="http://www.accademiafumettotrieste.it/" title="Accademia di Fumetto di Trieste, Italy by Yubaraj Rai" target="_blank">
                                        Accademia di Fumetto di Trieste, Italy
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//nabanepal.com/" title="Naba Nepal | Online News by Yubaraj Rai" target="_blank">
                                        <img src={portfolioFour} alt="Naba Nepal | Online News" title="Naba Nepal | Online News" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//nabanepal.com/" title="Naba Nepal | Online News by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Naba Nepal | Online News" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioFour} alt="Naba Nepal | Online News" title="Naba Nepal | Online News" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//nabanepal.com/" title="Naba Nepal | Online News by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Naba Nepal | Online News</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Custom work on fields and features (No Plugins):</p>
                                                <span className="tag">Custom Widget</span>
                                                <span className="tag">Custom Fields</span>
                                                <br /><br />
                                                <h5>Front-end technologies:</h5>
                                                <span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">BEM</span><span className="tag">JavaScript</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//nabanepal.com/" title="Naba Nepal | Online News by Yubaraj Rai" target="_blank">
                                        Naba Nepal | Online News
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//nirajshrestha.name.np/" title="Dr. Rtn. Niraj Shrestha Blog by Yubaraj Rai" target="_blank">
                                        <img src={portfolioSix} alt="Dr. Rtn. Niraj Shrestha Blog" title="Dr. Rtn. Niraj Shrestha Blog" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//nirajshrestha.name.np/" title="Dr. Rtn. Niraj Shrestha Blog by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Dr. Rtn. Niraj Shrestha Blog" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioSix} alt="Dr. Rtn. Niraj Shrestha Blog" title="Dr. Rtn. Niraj Shrestha Blog" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//nirajshrestha.name.np/" title="Dr. Rtn. Niraj Shrestha Blog by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Dr. Rtn. Niraj Shrestha Blog</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Custom Framework using Composer with listed Packages:</p>
                                                <span className="tag">PSR0 / PSR4 Rules to handle Custom Post Type Object</span><span className="tag">Custom Widget</span>
                                                <span className="tag">Carbon Fields</span><span className="tag">CMB2</span><span className="tag">Kirki</span>
                                                <br /><br />
                                                <h5>Front-end Framework</h5>
                                                <span className="tag">Foundation</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//nirajshrestha.name.np/" title="Dr. Rtn. Niraj Shrestha Blog Website by Yubaraj Rai" target="_blank">
                                        Dr. Rtn. Niraj Shrestha Blog
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-4 d-flex">
                            <div className="portfolio-cart fullwidth">
                                <figure className="relative">
                                    <Link to="//nepalmemorable.com/" title="Trekking Website by Yubaraj Rai" target="_blank">
                                        <img src={portfolioEight} alt="Trekking Website" title="Trekking Website" />
                                    </Link>
                                    <div className="portfolio-cart__options">
                                        <Link to="//nepalmemorable.com/" title="Trekking Website by Yubaraj Rai" target="_blank"><FaLink /></Link>
                                        <Link to="#" title="Preview of Trekking Website" className="preview__toggle"><FaEye /></Link>
                                    </div>
                                </figure>
                                <div className="preview">
                                    <div className="preview__inner relative">
                                        <span className="preview__close">
                                            <FaWindowClose />
                                        </span>
                                        <div className="preview__image">
                                            <figure className="figure">
                                                <img src={portfolioEight} alt="Trekking Website" title="Trekking Website" />
                                            </figure>
                                            <div className="preview__links">
                                                <Link to="//nepalmemorable.com/" title="Trekking Website by Yubaraj Rai" target="_blank"><FaLink /><br />Visit Website</Link>
                                            </div>
                                        </div>
                                        <div className="preview__detail">
                                            <h3>Trekking Website</h3>
                                            <p>This project has been developed under WordPress Content Management System (CMS) with different technologies as it's requirement.</p>
                                            <hr />
                                            <div className="preview__tags">
                                                <h4>Technologies used:</h4><br />
                                                <span className="tag">PHP</span><span className="tag">MySQL</span><span className="tag">JavaScript</span><span className="tag">HTML</span><span className="tag">CSS</span><span className="tag">Gulp</span><span className="tag">SASS</span><span className="tag">SCSS</span><span className="tag">jQuery</span>
                                                <hr />
                                                <h4>Frameworks:</h4><br />
                                                <h5>Back-end Framework</h5>
                                                <p>Custom Framework using Composer with listed Packages:</p>
                                                <span className="tag">PSR0 / PSR4 Rules to handle Custom Post Type Object</span><span className="tag">Custom Widget</span>
                                                <span className="tag">Carbon Fields</span><span className="tag">CMB2</span><span className="tag">Kirki</span>
                                                <br /><br />
                                                <h5>Front-end Framework</h5>
                                                <span className="tag">Foundation</span>
                                            </div>
                                            <hr />
                                            <div className="preview__job">
                                                <h4>My Job</h4><br />
                                                <ul>
                                                    <li>Full-stack Development</li>
                                                    <li>File, Database, and E-Mail Management on Hosting</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="//nepalmemorable.com/" title="Trekking Website Website by Yubaraj Rai" target="_blank">
                                        Trekking Website
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>
                <SectionWithBG3 id="contact" className="section section-padding relative text-center clip-path-1">
                    <h1 className="title-tag">
                        <FaEnvelopeOpen />
                        <small>Contact Me</small>
                    </h1>
                    <div className="box col-holder">
                        <div className="col col-12 contact-detail">
                            <ul>
                                <li><strong>Rai, Yubaraj</strong>
                                <br /><span className="tag">Microsoft Certified Professional (MCP)</span>
                                <hr /></li>
                                <li>Kathmandu, Nepal</li>
                                <li>Voice: <a href="tel:+977 9840051645" title="+977 9840051645">+977 9840051645</a></li>
                                <li>E-Mail: <a href="mailto:yubaraj.name@gmail.com" title="yubaraj.name@gmail.com">yubaraj.name@gmail.com</a></li>
                            </ul>
                        </div>
                    </div>
                </SectionWithBG3>
            </>
        );
    }
}

export default Home;