import React from 'react'
/**
 * Importing clientview components
 */
import Header from '../../components/clientview/header';
import Footer from '../../components/clientview/footer';
/**
 * @import importing pages
 */
import Home from './home';

function Index() {
    return (
        <>
            <Header />
            <Home />
            <Footer />
        </>
    )
}

export default Index
