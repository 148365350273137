import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './header.css';

class Header extends Component {
    state = {}

    componentDidMount = () => {
        /** ***************************************
     * @Navigation Script Writing
     * */
        // get all DOM element that we need for interactivity.
        var btnNavbar = document.querySelectorAll('.toggle-nav'),
            sectionTags = document.querySelectorAll("section");

        for (let i = 0; i < btnNavbar.length; i++) {
            // on button click job
            btnNavbar[i].addEventListener('click', function (e) {
                if (btnNavbar[i].classList.contains('active')) {
                    btnNavbar[i].classList.remove('animate');
                    setTimeout(function () {
                        btnNavbar[i].classList.remove('active');
                    }, 800);
                } else {
                    setTimeout(function () {
                        btnNavbar[i].classList.add('animate');
                    }, 800);
                    btnNavbar[i].classList.add('active');
                }

                var listWrapper = document.getElementById('menu'),
                    listItems = document.getElementById('menu').children,
                    listItemsRiverse = Object.keys(listItems).reverse();

                Object.keys(listItems).map(litem => {
                    if (listItems[litem].classList.contains('show')) {
                        var li = listItems[litem];
                        listWrapper.classList.remove('active');
                        setTimeout(function () {
                            li.classList.remove('show');
                        }, li * 150); // delay 150 ms
                    } else {
                        listWrapper.classList.add('active');
                        Object.keys(listItemsRiverse).map(li_i => {
                            setTimeout(function () {
                                listItems[listItemsRiverse[li_i]].classList.add('show');
                            }, listItemsRiverse[li_i] * 150); // delay 150 ms
                            return 1;
                        });
                    }
                    return 1;
                });
            });

            /**
             * get in section from menu with id
             */
            var menuAnchor = document.querySelectorAll("#menu a");
            Object.keys(menuAnchor).map(anchor => {
                menuAnchor[anchor].addEventListener('click', (e) => {
                    window.location.href = menuAnchor[anchor];
                    let menuChildren = menuAnchor[anchor].parentElement.parentElement.children;
                    Object.keys(menuChildren).map(children => {
                        menuChildren[children].classList.remove('current-menu-item');

                        for (let i = 0; i < btnNavbar.length; i++) {
                            btnNavbar[i].classList.remove("active");
                            btnNavbar[i].classList.remove("animate");
                            btnNavbar[i].nextElementSibling.classList.remove("active");
                            Object.keys(btnNavbar[i].nextElementSibling.children).map(nESC_item => {
                                btnNavbar[i].nextElementSibling.children[nESC_item].classList.remove("show");
                                return 1;
                            })
                        }
                        return 1;
                    });
                    menuAnchor[anchor].parentElement.classList.add('current-menu-item');
                });
                return 1;
            });
        }

        /**
         * remove menu class clicking on sections
         * */
        for (let j = 0; j < sectionTags.length; j++) {
            sectionTags[j].onclick = function (e) {
                e.stopPropagation();
                for (let i = 0; i < btnNavbar.length; i++) {
                    btnNavbar[i].classList.remove("active");
                    btnNavbar[i].classList.remove("animate");
                    btnNavbar[i].nextElementSibling.classList.remove("active");
                    Object.keys(btnNavbar[i].nextElementSibling.children).map(nESC_item => {
                        btnNavbar[i].nextElementSibling.children[nESC_item].classList.remove("show");
                        return 1;
                    })
                }
            }
        }
        /**
         * endNavigation
         * **************************/
    }

    render() {
        return (
            <header id="header">
                <div className="box col-holder">
                    <figure className="site-logo col col-3">
                        <Link to="/">
                            <svg id="yubarajRaiLogo" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="60.000000pt" height="20.000000pt" viewBox="0 0 95.000000 55.000000">
                                <metadata>
                                YUBARAJ RAI, DEVELOPER AS A SERVICE SINCE 2013.
                                </metadata>
                                <g transform="translate(0.000000,55.000000) scale(0.050000,-0.050000)"
                                fill="#000000" stroke="none">
                                <path d="M14 1088 c-8 -8 -14 -102 -14 -209 l0 -194 148 -133 148 -132 122 0
                                122 0 0 -70 0 -70 -270 0 -270 0 0 -140 0 -140 275 1 275 1 135 174 135 174
                                -5 370 -5 370 -130 0 -130 0 -6 -195 -5 -195 -129 0 -129 0 -5 195 -6 195
                                -121 6 c-66 3 -127 -1 -135 -8z"/>
                                <path d="M1157 1075 c-17 -14 -86 -99 -154 -189 l-123 -164 0 -361 0 -361 150
                                0 149 0 6 395 5 395 263 6 263 5 82 138 c45 76 82 143 82 149 0 25 -692 12
                                -723 -13z"/>
                                </g>
                            </svg>
                        </Link>
                    </figure>
                    <nav className="main-navigation relative col col-9">
                        <Link to="#" className="toggle-nav">
                            <span className="icon-bar"></span>
                        </Link>
                        <ul id="menu">
                            <li className="current-menu-item"><Link to="#welcome">Home</Link></li>
                            <li><Link to="#about">About</Link></li>
                            <li><Link to="#whatIdo">What I Do?</Link></li>
                            <li><Link to="#portfolio">Portfolio</Link></li>
                            <li><Link to="#contact">Contact</Link></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Header;