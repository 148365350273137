import React from 'react';

function Footer() {
    return (
        <footer id="footer">
            <div className="box col-holder">
                <div className="col col-12 text-center">
                    <p>
                        &copy;2009-{ new Date().getFullYear() }&nbsp;
                        <a href="http://www.google.com/search?q=Yubaraj+Rai" title='Mr. Yubaraj Rai' target='_blank'>
                            Mr. Yubaraj Rai
                        </a>,&nbsp;All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
